import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-edit-url',
  templateUrl: './edit-url.component.html',
  styleUrls: ['./edit-url.component.css']
})
export class EditUrlComponent implements OnInit {

  isLoading : boolean = false;
  randomURL : any = ''
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmopqrstuvwxyz1234567890";
  userInterface : any = false;

  activeAdmin  : any= false;

  constructor(
    private route : ActivatedRoute,
    private dataService : DataService,
    private router: Router,
    private authService: AuthService
  ) { }

  id : any = false
  urlData : any;
  qrcodeImage : File;

  ngOnInit() {
    
    this.activeAdmin = this.authService.getUser();

    this.dataService.setTitle('Edit URL')
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getUrl();
      }
    )
  }

  getUrl() {

    this.isLoading = true
    this.dataService.__post('/url', {id: this.id})
    .subscribe(
      (success) => {
        this.isLoading = false
        this.urlData = success
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  onFileSelect(files : Array<File>){
    this.qrcodeImage = files[0];
    // console.log('this.qrcodeImage',this.qrcodeImage)
  }

  onEditURL( formData : any) {
    
    
      
    if(!formData.valid) {
      return false;
    }
    var formValues = new FormData();

    if(!this.qrcodeImage){
      this.qrcodeImage = this.urlData.QRCode;
    }

    formValues.append('qrcode',this.qrcodeImage);

    formValues.append('id',this.id);
    Object.keys(formData.value).forEach(key => {
      formValues.append(key,formData.value[key]);
    })


    this.isLoading = true
    this.dataService.__post('/url/edit', formValues,'#editUrl')
    .subscribe(
      (success) => {
        this.isLoading = false
        this.router.navigateByUrl('/urls')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
 
  
  onGenerate() {
    this.urlData.url = this.makeRandom(20, this.possible);
  }

  getUserInterface (isSubscribe = false) {

    if(!this.urlData.activatedUser) return false;

      var url = environment.LIVE_URL + '/master-auth/' + this.urlData.activatedUser._id + '/' + this.activeAdmin._id;

      if(isSubscribe){
        url  = url + '?type=s';
      }

      window.open(
        url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

}
