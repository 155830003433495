import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-vcard',
  templateUrl: './create-vcard.component.html',
  styleUrls: ['./create-vcard.component.css']
})
export class CreateVcardComponent implements OnInit {

  user: any = false;
  selectedImage : any = false
  medias: Array<any> = []
  isLoading : any = false
  path: any = environment.API_URL

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Create V-Card | User')
    this.route.params.subscribe(
      (params) => {
        this.user = params['user']
        this.getMedias();
      }
    )
  }

  onFileSelect(files) {
    this.selectedImage = files[0]
  }

  getMedias() {
    this.isLoading = true
    this.dataService.__post('/medias', {})
    .subscribe(
      (success) => {
        this.medias = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onCreate(formData) {

    
    const data = new FormData();

    if(!this.selectedImage) 
    {
      this.dataService.showAlert('error', 'Error', 'Please Select Banner Image')
      return false;
    }

    var social : Array<any> = []

    for (var key in this.medias) {
      social.push({
        name : this.medias[key].name,
        icon : this.medias[key].icon,
        url : formData.value[this.medias[key].name]
      })
    }

    data.append('user', this.user)
    data.append('image', this.selectedImage)
    for (const key in formData.value) {
      data.append(key, formData.value[key])
    }
    data.append('socialMedias', JSON.stringify(social))
    
    this.dataService.__post('/v-card/create', data, '#addVcard')
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'V-Card Created Successfully')
        setTimeout(() => {}, 1000)
        this.router.navigateByUrl('/users')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

}
