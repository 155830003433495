import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-media',
  templateUrl: './create-media.component.html',
  styleUrls: ['./create-media.component.css']
})
export class CreateMediaComponent implements OnInit {

  icon : any = false
  

  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {
    this.dataService.setTitle('Add Social Media')
  }

  onChangeFile(event) {
    this.icon = event.target.files[0]
  }

  onSubmit(formData) {

    const data = new FormData();

    if(formData.value.name.trim() == '' || formData.value.placeholder.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return;
    }

    if(!this.icon) {
      this.dataService.showAlert('error', 'Error', 'Please select an icon')
      return;
    }

    
    data.append('icon', this.icon)
    data.append('name', formData.value.name)
    data.append('placeholder', formData.value.placeholder)
    data.append('mediaUrl', formData.value.url)

    this.dataService.__post('/media/create', data, '#addMedia')
    .subscribe(
      (success) => {
        this.router.navigateByUrl('/medias')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

}
