import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';


@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.css']
})
export class EditSubscriptionComponent implements OnInit {

  isLoading : boolean = false
  id : any = false
  subscriptionData : any;
  activeUser: any;

  users : Array<any> = []

  constructor(
    private route : ActivatedRoute,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Edit Subscriptions')
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getSubscription();
      }
    )
  }

  getSubscription() {

    this.isLoading = true
    this.dataService.__post('/subscription', {id : this.id})
    .subscribe(
      (success) => {
        this.subscriptionData = success 
        success.expiring = moment(success.expiring).format("YYYY-MM-DD");
        success.subscriptionStart = moment(success.subscriptionStart).format("YYYY-MM-DD");
        
          success.user.profile = environment.APP_PATH + '/assets/images/profile/' + success.user.name.trim().charAt(0).toUpperCase() + '.png'
       
        this.activeUser = success.user
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  onEditSubscription(formData) {

    if(!this.activeUser) {
      this.dataService.showAlert('error', 'Error', 'Please Select user')
      return
    }
    if(formData.value.paymentID.trim() == '' || formData.value.amount == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return
    }

    formData.value.id = this.id
    formData.value.user = this.activeUser._id

    this.isLoading = true
    this.dataService.__post('/subscription/edit', formData.value, '#editSubscription')
    .subscribe(
      (success) => {
        this.isLoading = false
        this.router.navigateByUrl('/subscriptions')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
    
  }

  searchUsers(event) {

    if(event.target.value.trim() == '') {
      this.users = []
      return false;
    }

    this.isLoading = true
    this.dataService.__post('/users', {search : event.target.value.trim()})
    .subscribe(
      (success) => {
        success.forEach(user => {
          user.profile = environment.APP_PATH + '/assets/images/profile/'+ user.name.trim().charAt(0).toUpperCase()+'.png'
        });
        this.users = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  doActive(user) {
    this.activeUser = user
    this.users = [];
  }

}
