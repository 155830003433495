import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

import { environment } from 'src/app/environments';
import { Router } from '@angular/router';


@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.css']
})
export class CreateSubscriptionComponent implements OnInit {

  isLoading : boolean = false
  activeUser: any;

  users : Array<any> = [];

  constructor(
    private dataService : DataService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Add Subscription')
  }

  searchUsers(event) {

    if(event.target.value.trim() == '') {
      this.users = []
      return false;
    }

    this.isLoading = true
    this.dataService.__post('/users', {search : event.target.value.trim()})
    .subscribe(
      (success) => {
        success.forEach(user => {
          user.profile = environment.APP_PATH + '/assets/images/profile/'+ user.name.trim().charAt(0).toUpperCase()+'.png'
        });
        this.users = success 
        this.isLoading = false

        console.log('this.users',this.users)

      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  doActive(user) {
    this.activeUser = user
    this.users = [];
  }



  onAddSubscription(formData : any){

    if(!this.activeUser) {
      this.dataService.showAlert('error', 'Error', 'Please Select user')
      return
    }

    formData.value.user = this.activeUser._id

    this.isLoading = true
    this.dataService.__post('/subscription/create', formData.value, '#addSubscription')
    .subscribe(
      (success) => {
        this.isLoading = false;
      this.dataService.showAlert('success', 'Success', 'Subscription added successfully')
        this.router.navigateByUrl('/subscriptions')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )


  }

}
