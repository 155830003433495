import { Component, OnInit } from '@angular/core';

import faker from 'faker';
import { DataService } from '../data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  isLoading : boolean = false;
  searching : boolean = false;
  users : Array<any> = [];


  

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.setTitle('Users')
    this.getUsers();
  }

  getUsers() {

    this.isLoading = true
    this.dataService.__post('/users', {})
    .subscribe(
      (success) => {
        this.users = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  onDelete(id) {
    if(!confirm('Are you really want to delete this User?')) return false;
    this.isLoading = true
    this.dataService.__post('/user/delete', {id : id})
    .subscribe(
      (success) => {
        this.isLoading = false
        this.getUsers()
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onSearching() {
    this.searching = !this.searching
    if(!this.searching) {
      this.getUsers()
    }
  }

  onSearch(event) {
    if(event.target.value.trim() == '') {
      this.getUsers()
      return;
    }
    this.isLoading = true
    this.dataService.__post('/users', {search : event.target.value.trim()})
    .subscribe(
      (success) => {
        this.users = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  changeStatus (id, status) {

    const data = {
      id: id,
      status: status
    }

    this.isLoading = true

    this.dataService.__post('/user/edit', data)
    .subscribe(
      (success) => {
        this.isLoading = false
        this.getUsers()
      },
      (error) => {
        this.isLoading = false
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }


  

}
