import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "../environments";

@Component({
  selector: "app-dropzone",
  templateUrl: "./dropzone.component.html",
  styleUrls: ["./dropzone.component.css"],
})
export class DropzoneComponent implements OnInit {


  files: File[] = [];
  imageSrc: any;
  @Output() onFileSelect = new EventEmitter();

  @Input('placeholder') placeholderImage : string;


  constructor() {}

  ngOnInit(): void {

    if(this.placeholderImage){
      this.imageSrc = environment.ASSETS_URL + this.placeholderImage; 
    }

  }

  onSelect(event) {
    this.files = event.addedFiles;
    const file = this.files[0];
    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;
    reader.readAsDataURL(file);

    this.onFileSelect.emit(this.files);

  }

  onRemove() {
    this.files = [];
    this.imageSrc = false;
  }
}
