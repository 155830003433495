import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  show2FA : boolean = false;
  user : any = false;
  form : any = false;

  constructor(
    private dataService : DataService,
    private Auth : AuthService,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Login')
    if(this.Auth.isAuth()) this.router.navigateByUrl('/dashboard')
  }

  onLogin(formData) {

    if(formData.value.email.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return;
    }

    this.dataService.__post('/login', formData.value, '')
    .subscribe(
      (success) => {
        this.user = success;
        this.show2FA = true;
        this.form = formData.value;
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }
  
  
  verifyOTP(formData) {

    if(!formData.valid) {
      this.dataService.showAlert('error', 'Error', 'Please enter OTP');
      return;
    }

    const data = {
      otp : formData.value.otp, 
      user : this.user,
      email : this.form.email,
      password : this.form.password
    }

    this.dataService.__post('/2fa', data, '')
    .subscribe(
      (success) => {
        this.Auth.setUser(success);
        this.Auth.setToken(success.token);
        this.router.navigateByUrl('/dashboard')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

}
