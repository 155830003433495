import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http'
// import { HttpModule } from '@angular/http';
// import { StripeModule } from "stripe-angular"

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UrlsComponent } from './urls/urls.component';
import { UsersComponent } from './users/users.component';
import { CardsComponent } from './cards/cards.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { LoginComponent } from './login/login.component';
import { CreateUrlComponent } from './urls/create-url/create-url.component';
import { EditUrlComponent } from './urls/edit-url/edit-url.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { EditSubscriptionComponent } from './subscriptions/edit-subscription/edit-subscription.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './profile/profile.component';
import { ToastrModule } from 'ngx-toastr';
import { CreateSocialComponent } from './users/create-social/create-social.component';
import { CreateBusinessComponent } from './users/create-business/create-business.component';
import { CreateVcardComponent } from './users/create-vcard/create-vcard.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SocialMediaComponent } from './social-media/social-media.component';
import { CreateMediaComponent } from './social-media/create-media/create-media.component';
import { EditMediaComponent } from './social-media/edit-media/edit-media.component';

import { ChartsModule } from 'ng2-charts';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QrComponent } from './qr/qr.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgxKjuaModule } from 'ngx-kjua';
import { VcardProfileComponent } from './vcard-profile/vcard-profile.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { SocialProfileComponent } from './social-profile/social-profile.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { ViewSocialProfileComponent } from './cards-view/view-social-profile/view-social-profile.component';
import { ViewBusinessProfileComponent } from './cards-view/view-business-profile/view-business-profile.component';
import { ViewCompanyProfileComponent } from './cards-view/view-company-profile/view-company-profile.component';
import { ViewUserComponent } from './users/view-user/view-user.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CreateMassiveUrlComponent } from './massive-urls/create-massive-url/create-massive-url.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PlaceholderLoaderComponent } from './placeholder-loader/placeholder-loader.component';
import { CreateSubscriptionComponent } from './create-subscription/create-subscription.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DefaultComponent,
    DashboardComponent,
    UrlsComponent,
    UsersComponent,
    CardsComponent,
    SubscriptionsComponent,
    LoginComponent,
    CreateUrlComponent,
    EditUrlComponent,
    CreateUserComponent,
    EditUserComponent,
    EditSubscriptionComponent,
    ProfileComponent,
    CreateSocialComponent,
    CreateBusinessComponent,
    CreateVcardComponent,
    SocialMediaComponent,
    CreateMediaComponent,
    EditMediaComponent,
    QrComponent,
    ResetLinkComponent,
    ForgotPasswordComponent,
    VcardProfileComponent,
    BusinessProfileComponent,
    SocialProfileComponent,
    NotFoundComponent,
    LoaderComponent,
    DropzoneComponent,
    ViewSocialProfileComponent,
    ViewBusinessProfileComponent,
    ViewCompanyProfileComponent,
    ViewUserComponent,
    CreateMassiveUrlComponent,
    PlaceholderLoaderComponent,
    CreateSubscriptionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HighchartsChartModule,
    ChartsModule,
    NgxQRCodeModule,
    NgxKjuaModule,
    NgxDropzoneModule,
    // StripeModule.forRoot("pk_test_51IZHKfIHBUjxmoNTIHgLzJ8ZqubUYR6gGJd4L9o5lfpLtbjlhpOzr8XYhychkDg7zRKU1OF00RWvZWxBMxbNGrqP00rZibD7Mq"),
    NgxDaterangepickerMd.forRoot(),
    TooltipModule,
    ColorPickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
