import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { environment } from '../environments';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  type: any = 'All Cards'
  path: any = environment.API_URL
  activeCard: any = false
  isLoading: boolean = false
  socialCards: Array<any> = []
  businessCards: Array<any> = []
  companyCards: Array<any> = []

  title = 'app';
  elementType = 'url';
  value = 'Techiediaries';

  activeAdmin: any = false;
  statastic: any = false;

  doughnutChartLabels: Label[] = ['Active', 'Deactivated'];
  doughnutChartType: ChartType = 'doughnut';


  SocialChartData: MultiDataSet = [
    [0, 0]
  ];
  BusinessChartData: MultiDataSet = [
    [0, 0]
  ];
  CompanyChartData: MultiDataSet = [
    [0, 0]
  ];

  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.activeAdmin = this.authService.getUser();

    this.cardStatasric();

    this.dataService.setTitle('Cards')
    this.getAllCards()
  }

  getAllCards(type = 'All Cards') {
    this.getCards('Social Cards');
    this.getCards('Business Cards');
    this.getCards('Company Profile');
    this.type = type
  }

  getCards(type = 'Social Cards') {
    this.isLoading = true
    this.dataService.__post('/cards', { type: type })
      .subscribe(
        (success) => {
          if (type == 'Social Cards') this.socialCards = success
          if (type == 'Business Cards') this.businessCards = success
          if (type == 'Company Profile') this.companyCards = success
          this.isLoading = false
        },
        (error) => {
          this.isLoading = false
          console.log('error : ', error)
          this.dataService.showAlert('error', 'Error', error)
        }
      )
  }

  setActiveCard(card) {
    this.activeCard = card
  }

  changeType(type) {
    this.type = type
    // this.getCards();
  }

  getQR(id, text) {
    return environment.APP_PATH + '/admin' + text + id
  }

  onDelete(id, type) {
    if (!confirm('Do you really want to delete this Card?')) return false;
    this.isLoading = true
    this.dataService.__post('/card/delete', { id: id, type: type })
      .subscribe(
        (success) => {
          this.isLoading = false
          console.log(success)
          this.getCards(type)
        },
        (error) => {
          this.isLoading = false
          console.log('error : ', error)
          this.dataService.showAlert('error', 'Error', error)
        }
      )
  }

  changeStatus(id, type, status) {
    const data = {
      id,
      type,
      status
    }

    this.isLoading = true
    this.dataService.__post('/card/status-change', data)
      .subscribe(
        (success) => {
          this.isLoading = false
          this.getAllCards(this.type)
        },
        (error) => {
          this.isLoading = false
          console.log('error : ', error)
          this.dataService.showAlert('error', 'Error', error)
        }
      )

  }



  SactiveCreatedAtSort: boolean = false;
  SactiveUpdatedAtSort: boolean = false;
  SactiveVisitedAtSort: boolean = false;


  BactiveCreatedAtSort: boolean = false;
  BactiveUpdatedAtSort: boolean = false;
  BactiveVisitedAtSort: boolean = false;

  CactiveCreatedAtSort: boolean = false;
  CactiveUpdatedAtSort: boolean = false;
  CactiveVisitedAtSort: boolean = false;



  onSort(sortBy, cardName) {

    if (cardName == 'socialCard') {
      if (sortBy == 'created_at') {
        this.SactiveCreatedAtSort = !this.SactiveCreatedAtSort;
        this.socialCards.sort((a, b) => {
          return this.SactiveCreatedAtSort ? a.created_at.localeCompare(b.created_at) : b.created_at.localeCompare(a.created_at)
        })
      }


      if (sortBy == 'updated_at') {
        this.SactiveUpdatedAtSort = !this.SactiveUpdatedAtSort;
        this.socialCards.sort((a, b) => {
          return this.SactiveUpdatedAtSort ? a.updated_at.localeCompare(b.updated_at) : b.updated_at.localeCompare(a.updated_at)
        })
      }

      if (sortBy == 'visits') {
        this.SactiveVisitedAtSort = !this.SactiveVisitedAtSort;
        this.socialCards.sort((a, b) => {
          return this.SactiveVisitedAtSort ? Number(a.visits) - Number(b.visits) : Number(b.visits) - Number(a.visits);
        })

      }

    }

    if (cardName == 'businessCard') {

      if (sortBy == 'created_at') {
        this.BactiveCreatedAtSort = !this.BactiveCreatedAtSort;
        this.businessCards.sort((a, b) => {
          return this.BactiveCreatedAtSort ? a.created_at.localeCompare(b.created_at) : b.created_at.localeCompare(a.created_at)
        })
      }


      if (sortBy == 'updated_at') {
        this.BactiveUpdatedAtSort = !this.BactiveUpdatedAtSort;
        this.businessCards.sort((a, b) => {
          return this.BactiveUpdatedAtSort ? a.updated_at.localeCompare(b.updated_at) : b.updated_at.localeCompare(a.updated_at)
        })
      }

      if (sortBy == 'visits') {
        this.BactiveVisitedAtSort = !this.BactiveVisitedAtSort;
        this.businessCards.sort((a, b) => {
          return this.BactiveVisitedAtSort ? Number(a.visits) - Number(b.visits) : Number(b.visits) - Number(a.visits);
        })
      }


    }

    if (cardName == 'companyCard') {

      if (sortBy == 'created_at') {
        this.CactiveCreatedAtSort = !this.CactiveCreatedAtSort;
        this.companyCards.sort((a, b) => {
          return this.CactiveCreatedAtSort ? a.created_at.localeCompare(b.created_at) : b.created_at.localeCompare(a.created_at)
        })
      }


      if (sortBy == 'updated_at') {
        this.CactiveUpdatedAtSort = !this.CactiveUpdatedAtSort;
        this.companyCards.sort((a, b) => {
          return this.CactiveUpdatedAtSort ? a.updated_at.localeCompare(b.updated_at) : b.updated_at.localeCompare(a.updated_at)
        })
      }

      if (sortBy == 'visits') {
        this.CactiveVisitedAtSort = !this.CactiveVisitedAtSort;
        this.companyCards.sort((a, b) => {
          return this.CactiveVisitedAtSort ? Number(a.visits) - Number(b.visits) : Number(b.visits) - Number(a.visits);
        })
      }

    }
  }


  userInteface(card) {

    if (!card.user) {
      this.dataService.showAlert('error', 'Error', 'User not associated with this card!');
      return false;
    }

    window.open(
      environment.LIVE_URL + '/master-auth/' + card.user._id + '/' + this.activeAdmin._id,
      '_blank' // <- This is what makes it open in a new window.
    );

  }



  cardStatasric() {

    this.dataService.__post('/card/statastic', {})
      .subscribe(
        (success) => {
          this.statastic = success;

          this.SocialChartData = [
            [success.socialCardStatus.Active, success.socialCardStatus.Deactivate]
          ];

          this.BusinessChartData = [
            [success.BusinessCardStatus.Active, success.BusinessCardStatus.Deactivate]
          ];

          this.CompanyChartData = [
            [success.CompanyCardStatus.Active, success.CompanyCardStatus.Deactivate]
          ];

        },

        (error) => {
          console.log('Err', error);
        }
      )


  }

}


