import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-social',
  templateUrl: './create-social.component.html',
  styleUrls: ['./create-social.component.css']
})
export class CreateSocialComponent implements OnInit {

  user: any = false;
  isLoading: any = false;
  medias : Array<any> = []
  path: any = environment.API_URL
  profile: any = false

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Create Social Profile | User')
    this.route.params.subscribe(
      (params) => {
        this.user = params['user']
      }
    )
    this.getMedias();
  }

  getMedias() {
    this.isLoading = true
    this.dataService.__post('/medias', {})
    .subscribe(
      (success) => {
        this.medias = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onFileSelect(files) {
    this.profile = files[0]
  }

  onCreate(formData) {

    if(!this.profile) {
      this.dataService.showAlert('error', 'Error', 'Please select a profile')
      return false;
    }

    var social : Array<any> = []

    for (var key in this.medias) {
      social.push({
        name : this.medias[key].name,
        icon : this.medias[key].icon,
        url : formData.value[this.medias[key].name]
      })
    }

    var data = new FormData();

    data.append('name', formData.value.name)
    data.append('surname', formData.value.surname)
    data.append('aboutMe', formData.value.aboutMe)
    data.append('socialMedias', JSON.stringify(social))
    data.append('user', this.user)
    data.append('profile', this.profile)

    this.dataService.__post('/social-card/create', data, '#addSocial')
    .subscribe(
      (success) => {
        this.router.navigateByUrl('/users')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

}
