import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.css']
})
export class CreateBusinessComponent implements OnInit {

  user: any = false;
  selectedImage : any = false
  medias: Array<any> = []
  isLoading : any = false
  path: any = environment.API_URL

  timeSlots : Array<any> = [
    '08:00 am',
    '09:00 am',
    '10:00 am',
    '11:00 am',
    '12:00 pm',
    '01:00 pm',
    '02:00 pm',
    '03:00 pm',
    '04:00 pm',
    '05:00 pm',
    '06:00 pm',
    '07:00 pm',
    '08:00 pm'
  ]


  openingHourse : Array<any> = [
    {
      key : 'monday',
      isWorkingDay : false,
      name : 'Monday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'tuesday',
      isWorkingDay : false,
      name : 'Tuesday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'wednesday',
      isWorkingDay : false,
      name : 'Wednesday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'thursday',
      isWorkingDay : false,
      name : 'Thursday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'friday',
      isWorkingDay : false,
      name : 'Friday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'saturday',
      isWorkingDay : false,
      name : 'Saturday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'Sunday',
      isWorkingDay : false,
      name : 'Sunday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    }
  ]

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Create Business Card | User')
    this.route.params.subscribe(
      (params) => {
        this.user = params['user']
        this.getMedias();
      }
    )
  }

  addNewTimeSlot(slot){
    slot.openingHours.push({
      start : '08:00 am',
      end : '08:00 pm'
    })
  }

  removeHourSlot(slot,i){
    slot.openingHours.splice(i,1);
  }

  changeWorkingDay(slot){
    slot.isWorkingDay = !slot.isWorkingDay
  }


  onFileSelect(files) {
    this.selectedImage = files[0]
  }

  getMedias() {
    this.isLoading = true
    this.dataService.__post('/medias', {})
    .subscribe(
      (success) => {
        this.medias = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onCreate(formData) {

    
    const data = new FormData();

    if(!this.selectedImage) 
    {
      this.dataService.showAlert('error', 'Error', 'Please Select Banner Image')
      return false;
    }

    var social : Array<any> = []

    for (var key in this.medias) {
      social.push({
        name : this.medias[key].name,
        icon : this.medias[key].icon,
        url : formData.value[this.medias[key].name]
      })
    }

    // let location = [ formData.value.latitude, formData.value.longitude ]

    console.log(this.openingHourse)

    data.append('user', this.user)
    data.append('banner', this.selectedImage)
    // data.append('location', JSON.stringify(location))
    data.append('openingHours', JSON.stringify(this.openingHourse));
    for (const key in formData.value) {
      data.append(key, formData.value[key])
    }
    data.append('socialMedias', JSON.stringify(social))

    this.dataService.__post('/business-card/create', data, '#addBusiness')
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Business Card Created Successfully')
        setTimeout(() => {}, 1000)
        this.router.navigateByUrl('/users')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

}
