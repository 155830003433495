import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any = false
  API_PATH : any = ''

  @ViewChild('toggle') toggle: ElementRef<HTMLElement>

  constructor(
    private Auth : AuthService
  ) { }

  ngOnInit() {
    this.API_PATH = environment.API_URL
    this.user = this.Auth.getUser()
  }

  logout() {
    this.Auth.logout();
  }

  getProfile() {
    if (this.user.profile) {
      return environment.API_URL + '/images/' + this.user.profile
    } else {
      return environment.APP_PATH + '/assets/images/profile/' + this.user.name.trim().charAt(0).toUpperCase() + '.png'
    }
  }

  clickMenuItem() {
    let el : HTMLElement = this.toggle.nativeElement
    el.click();
  }

}
