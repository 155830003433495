import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-massive-url',
  templateUrl: './create-massive-url.component.html',
  styleUrls: ['./create-massive-url.component.css']
})
export class CreateMassiveUrlComponent implements OnInit {

  defaultStatus: any = "Activated"

  foregroundColor1 : any = '#000000'
  foregroundColor2 : any = '#000000'
  eyeColor1 : any = '#000000'
  eyeColor2 : any = '#000000'
  backgroundColor : any = '#ffffff'

  colorGradient: boolean = false
  customEyeColor: boolean = false

  EyeGradientType : any = 'Linear'
  ForGroundGradientType : any = 'Linear'

  body : string = 'square'
  eye : string = 'frame0'
  eyeBall : string = 'ball0'

  App_Path : any = environment.APP_PATH;

  bodyShapes = [
    { value: 'square', path : 'square.png' },
    { value: 'mosaic', path : 'mosaic.png' },
    { value: 'dot', path : 'dot.png' },
    { value: 'circle', path : 'circle.png' },
    { value: 'circle-zebra', path : 'circle-zebra.png' },
    { value: 'circle-zebra-vertical', path : 'circle-zebra-vertical.png' },
    { value: 'circular', path : 'circular.png' },
    { value: 'edge-cut', path : 'edge-cut.png' },
    { value: 'edge-cut-smooth', path : 'edge-cut-smooth.png' },
    { value: 'japnese', path : 'japnese.png' },
    { value: 'leaf', path : 'leaf.png' },
    { value: 'pointed', path : 'pointed.png' },
    { value: 'pointed-edge-cut', path : 'pointed-edge-cut.png' },
    { value: 'pointed-in', path : 'pointed-in.png' },
    { value: 'pointed-in-smooth', path : 'pointed-in-smooth.png' },
    { value: 'pointed-smooth', path : 'pointed-smooth.png' },
    { value: 'round', path : 'round.png' },
    { value: 'rounded-in', path : 'rounded-in.png' },
    { value: 'rounded-in-smooth', path : 'rounded-in-smooth.png' },
    { value: 'rounded-pointed', path : 'rounded-pointed.png' },
    { value: 'star', path : 'star.png' },
    { value: 'diamond', path : 'diamond.png' },
  ];

  eyeFrameShapes = [
    { 
      value: 'frame0', 
      path: 'frame0.png',
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame1', 
      path: 'frame1.png',
      options : {
        erf1 : ["fh"],
        erf2 : [],
        erf3 : ["fh", "fv"],
      },
    },
    { 
      value: 'frame2', 
      path: 'frame2.png',
      options : {
        erf1 : ["fv"],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame3', 
      path: 'frame3.png',
      options : {
        erf1 : ["fv"],
        erf2 : ["fv", "fh"],
        erf3 : [],
      },
    },
    { 
      value: 'frame4', 
      path: 'frame4.png',
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame5', 
      path: 'frame5.png',
      options : {
        erf1 : ["fh"],
        erf2 : [],
        erf3 : ["fh", "fv"],
      },
    },
    { 
      value: 'frame6', 
      path: 'frame6.png',
      options : {
        erf1 : ["fv"],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame7', 
      path: 'frame7.png' ,
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame8', 
      path: 'frame8.png',
      options : {
        erf1 : ["fv"],
        erf2 : ["fh", "fv"],
        erf3 : [],
      },
    },
    { 
      value: 'frame10', 
      path: 'frame10.png',
      options : {
        erf1 : ["fv"],
        erf2 : ["fh", "fv"],
        erf3 : [],
      },
    },
    { 
      value: 'frame11', 
      path: 'frame11.png' ,
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame12', 
      path: 'frame12.png',
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame13', 
      path: 'frame13.png',
      options : {
        erf1 : [],
        erf2 : [],
        erf3 : [],
      },
    },
    { 
      value: 'frame14', 
      path: 'frame14.png',
      options : {
        erf1 : [],
        erf2 : ["fh"],
        erf3 : ["fv"],
      },
    },
    { 
      value: 'frame16', 
      path: 'frame16.png',
      options : {
        erf1 : [],
        erf2 : ["fh"],
        erf3 : ["fv"],
      },
    },
  ]

  eyeBallShapes = [
    { 
      value : 'ball0', 
      path: 'ball0.png',
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      },
    },
    { 
      value : 'ball1', 
      path: 'ball1.png',
      options : {
        brf1 : ["fh"],
        brf2 : [],
        brf3 : ["fh","fv"],
      },
    },
    { 
      value : 'ball2', 
      path: 'ball2.png',
      options : {
        brf1 : ["fv"],
        brf2 : [],
        brf3 : [],
      },
    },
    { 
      value : 'ball3', 
      path: 'ball3.png',
      options : {
        brf1 : ["fv"],
        brf2 : ["fv","fh"],
        brf3 : [],
      },
    },
    { 
      value : 'ball5', 
      path: 'ball5.png',
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      },
    },
    { 
      value : 'ball6', 
      path: 'ball6.png',
      options : {
        brf1 : ["fv"],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball7', 
      path: 'ball7.png',
      options : {
        brf1 : ["fv"],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball8', 
      path: 'ball8.png',
      options : {
        brf1 : ["fv"],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball10', 
      path: 'ball10.png' ,
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball11', 
      path: 'ball11.png',
      options : {
        brf1 : ["fh"],
        brf2 : [],
        brf3 : ["fh", "fv"],
      },  
    },
    { 
      value : 'ball12', 
      path: 'ball12.png',
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball13', 
      path: 'ball13.png',
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball14', 
      path: 'ball14.png' ,
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball15', 
      path: 'ball15.png' ,
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball16', 
      path: 'ball16.png' ,
      options : {
        brf1 : [],
        brf2 : ["fh"],
        brf3 : ["fv"],
      }, 
    },
    { 
      value : 'ball17', 
      path: 'ball17.png' ,
      options : {
        brf1 : [],
        brf2 : ["fh"],
        brf3 : ["fv"],
      },
    },
    { 
      value : 'ball18', 
      path: 'ball18.png',
      options : {
        brf1 : [],
        brf2 : [],
        brf3 : [],
      }, 
    },
    { 
      value : 'ball19', 
      path: 'ball19.png',
      options : {
        brf1 : [],
        brf2 : ["fh"],
        brf3 : ["fv"],
      },
    },
  ]

  logo : any = false;
  imagePath : any = '';

  @ViewChild('logoPicker') logoPicker : ElementRef<HTMLElement>

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
  }

  setColorGradient(value) {
    this.colorGradient = value
  }

  setCustomEyeColor(event) {
    this.customEyeColor = event.target.checked
  }

  onGradientTypeChange(type, to = 'eye') {
    if(to == 'eye') {
      this.EyeGradientType = type
    } else {
      this.ForGroundGradientType = type
    }
  }

  exchangeEyeColor() {
    this.eyeColor1 = this.foregroundColor1
    this.eyeColor2 = this.foregroundColor2
  }

  exchangeForegroundColor() {
    var temp = this.foregroundColor1
    this.foregroundColor1 = this.foregroundColor2
    this.foregroundColor2 = temp
  }

  onLogoChange(event) {

    var reader = new FileReader();
    this.imagePath = event.target.files[0];
    reader.readAsDataURL(this.imagePath); 
    reader.onload = (_event) => { 
      this.logo = reader.result; 
    }

  }

  onSelectLogo() {
    let el : HTMLElement = this.logoPicker.nativeElement
    el.click();
  }

  removeLogo() {
    this.logo = false
  }

  onSetBodyShape(shape) {
    this.body = shape
  }
  onSetEyeShape(shape) {
    this.eye = shape
  }
  onSetEyeBallShape(shape) {
    this.eyeBall = shape
  }


  getERF(eye){

    const fI = this.eyeFrameShapes.findIndex(f => f.value == eye);
   
    if(fI !== -1){
      return this.eyeFrameShapes[fI].options;
    } 

    return  {
      erf1 : [],
      erf2 : [],
      erf3 : [],
    }

  } 


  getBRF(ball){

    const fI = this.eyeBallShapes.findIndex(f => f.value == ball);
   
    if(fI !== -1){
      return this.eyeBallShapes[fI].options;
    } 

    return  {
      brf1 : [],
      brf2 : [],
      brf3 : [],
    }

  } 


  onCreateURL(formData: any) {
    var form = new FormData();
    form.append('logo',this.imagePath);
    // form.append('status', formData.value.status);
    
    var data = formData.value;
    data.config = {};

    data.config.body = this.body;
    data.config.eye = this.eye;
    
    var eyeOptions = this.getERF(this.eye);

    Object.keys(eyeOptions).map(e => {
      data.config[e] = eyeOptions[e];
    });

    var ballOptions = this.getBRF(this.eyeBall);

    Object.keys(ballOptions).map(e => {
      data.config[e] = ballOptions[e];
    })

    data.config.eyeBall = this.eyeBall;
    data.config.bodyColor = this.foregroundColor1;
    data.config.bgColor = this.backgroundColor;
    data.config.eye1Color = this.eyeColor1;
    data.config.eye2Color = this.eyeColor1;
    data.config.eye3Color = this.eyeColor1;
    data.config.eyeBall1Color =  this.eyeColor1;
    data.config.eyeBall2Color =  this.eyeColor1;
    data.config.eyeBall3Color =  this.eyeColor1;



    if(this.colorGradient){
      
      data.config.gradientColor1 = this.foregroundColor1;
      data.config.gradientColor2 = this.foregroundColor2;
      data.config.gradientType =  this.EyeGradientType.toLowerCase();
      data.config.gradientOnEyes = true;

    }


    if(this.customEyeColor){

      data.config.gradientOnEyes = false;
      data.config.eyeBall1Color =  this.eyeColor2;
      data.config.eyeBall2Color =  this.eyeColor2;
      data.config.eyeBall3Color =  this.eyeColor2;

    }


    form.append('numberOfUrls',data.numberOfUrls);
    form.append('serialCode',data.serialCode);
    form.append('productCode',data.productCode);
    form.append('config',JSON.stringify(data.config));
    

    this.dataService.__post('/url/massive/generate',form,'#createUrl')
    .subscribe(
      (success) => {


        this.dataService.showAlert('success','Success',`${data.numberOfUrls} URLS Generated`);
        
        var name = success.split('/')[1];
        var link : any = document.createElement("a");
        link.download = name;
        link.href = success;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      },

      (error) => {
        this.dataService.showAlert('error','Error',error);
      }
    )

  }

}
