import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(
    private _http: HttpClient, 
    private titleService: Title,
    private toastr : ToastrService
  ) { }

  localUrl: string = 'http://localhost:9090/api/v1';
  liveUrl: string = 'https://api.youscanme.com/api/v1';
  apiUrl: string = (window.location.origin.match('localhost') !== null ? this.localUrl : this.liveUrl);
  
  
  

  setTitle(title: string = 'Home', main : string = '| Youscanme') {
    this.titleService.setTitle(`${title} ${main}`);
  }

  _response(response: any) {
    if (response.code !== 200) {
      throw new Error(response.data);
    }

    return response.data;
  }

  _handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage =
        error.message || 'Oops! Something went wrong, Please try again.';
    }
    return errorMessage;
  }

  __post(url: string, data: any, loaderElement: any = false) {

    var buttonName = "";

    if (loaderElement) {
      buttonName = $(loaderElement).text();
      $(buttonName).attr('disabled', true);
      $(loaderElement).html('<i class="fa fa-spin fa-spinner"></i> ' + buttonName);
    }

    return this._http.post(`${this.apiUrl}${url}`, data, { headers: this._getHeaders() }).pipe(
      map((response: any) => {

        // Remove Loader

        if (loaderElement) {
          $(buttonName).removeAttr('disabled');
          $(loaderElement).find('.fa').remove();
          $(loaderElement).html(buttonName);
        }

        return this._response(response);
      }),
      catchError((error: any) => {
        // this._toast.error(error)

        if (loaderElement) {
          $(loaderElement).find('.fa').remove();
          $(loaderElement).html(buttonName);
        }

        return throwError(this._handleError(error));
      })
    );
  }


  _getHeaders() {
    var token = this.getToken();
    return new HttpHeaders({ 'X-Auth-admin': (token ? token : 'unAuth') })
  }

  getToken() {
    return localStorage.getItem('X-Auth-admin');
  }

  showAlert(type, title, message) {
    if (type == 'success') {
      this.toastr.success(message, title);
    } else if (type == 'error') {
      this.toastr.error(message, title);
    }
  }


  changeKeyPosition(obj,mainKey){

    var newObj = {};
    newObj[mainKey] = obj[mainKey];
    
    delete obj[mainKey];

    Object.keys(obj).map((key) => {
      newObj[key] = obj[key]
    });

    console.log('ARRA',newObj);

    return newObj;


  }

}
