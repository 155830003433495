import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { environment } from '../environments';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user : any;
  profile : any = false

  constructor(private dataService : DataService, private Auth : AuthService) { }

  ngOnInit() {
    this.dataService.setTitle('User Account')
    this.user = this.Auth.getUser()
  }

  onProfileChange(event) {
    this.profile = event.target.files[0];
  }

  onChangePassword(formData) {
    

    if(formData.value.password.trim() != formData.value.confirmPassword.trim()) {
      this.dataService.showAlert('error', 'Error', 'Password and confirm password not match')
      return false
    }

    this.dataService.__post('/change-password', formData.value, '#changePassword')
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Password update successfully')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

  onUpdateProfile(formData) {

    const data = new FormData();

    if(formData.value.name.trim() == '' || formData.value.email.trim() == '' || formData.value.phoneNumber.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return;
    }

    if(this.profile != false) data.append('profile', this.profile);
    data.append('name', formData.value.name)
    data.append('email', formData.value.email)
    data.append('phoneNumber', formData.value.phoneNumber)

    this.dataService.__post('/update-profile', data, '#updateProfile')
    .subscribe(
      (success) => {
        this.Auth.setUser(success)
        this.Auth.setToken(success.token)
        this.dataService.showAlert('success', 'Success', 'Profile update successfully')
        window.location.href = environment.APP_PATH 
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

}
