import { Component, OnInit } from '@angular/core';

import faker from 'faker';
import { DataService } from '../data.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {

  isLoading : boolean = false;
  searching : boolean = false;
  filterBy : any = 'All'
  subscriptions : Array<any> = []

  activeUserSearch: boolean = false
  activePaymentIdSearch: boolean = false
  activeMethodSearch: boolean = false
  activeAmountSearch: boolean = false
  activePaymentStatusSearch: boolean = false
  activeSubscriptionSearch: boolean = false
  activeExpirationSearch: boolean = false
  activeSubsctiptionStatusSearch: boolean = false

  constructor(private dataService : DataService) { }

  ngOnInit() {
    this.dataService.setTitle('Subscriptions')
    this.getSubscriptions()
  }

  getSubscriptions() {

    this.isLoading = true
    this.dataService.__post('/subscriptions', {})
    .subscribe(
      (success) => {
        this.subscriptions = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  onDelete(id) {
    if(!confirm('Are you really want to delete this Subscription?')) return false;
    this.isLoading = true
    this.dataService.__post('/subscription/delete', {id : id})
    .subscribe(
      (success) => {
        this.isLoading = false
        this.getSubscriptions()
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onSearching() {
    this.searching = !this.searching
    if(!this.searching) {
      this.getSubscriptions()
    }
  }

  onFilter(value) {
    
    this.isLoading = true
    this.dataService.__post('/subscriptions', {status : value})
    .subscribe(
      (success) => {
        this.subscriptions = success 
        this.isLoading = false
        value.trim() == '' ? this.filterBy = 'All' : this.filterBy = value
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onSearch(event) {
    if(event.target.value.trim() == '') {
      this.getSubscriptions()
      return;
    }
    this.isLoading = true
    this.dataService.__post('/subscriptions', {search : event.target.value.trim()})
    .subscribe(
      (success) => {
        this.subscriptions = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onSort(sortBy) {
    if (sortBy == 'user') {
      this.activeUserSearch = !this.activeUserSearch
      this.subscriptions.sort((a, b) => {
        if(a.user && b.user) return this.activeUserSearch ? a.user.email.localeCompare(b.user.email) : b.user.email.localeCompare(a.user.email)
      })
    }
    if (sortBy == 'paymentId') {
      this.activePaymentIdSearch = !this.activePaymentIdSearch
      this.subscriptions.sort((a, b) => {
         return this.activePaymentIdSearch ? a.paymentID.localeCompare(b.paymentID) : b.paymentID.localeCompare(a.paymentID)
      })
    }
    if (sortBy == 'method') {
      this.activeMethodSearch = !this.activeMethodSearch
      this.subscriptions.sort((a, b) => {
         return this.activeMethodSearch ? a.paymentMethod.localeCompare(b.paymentMethod) : b.paymentMethod.localeCompare(a.paymentMethod)
      })
    }
    if (sortBy == 'amount') {
      this.activeAmountSearch = !this.activeAmountSearch;
      this.subscriptions.sort((a, b) => {
        return this.activeAmountSearch ? Number(a.amount) - Number(b.amount) : Number(b.amount) - Number(a.amount);
      })

    }
    if (sortBy == 'paymentStatus') {
      this.activePaymentStatusSearch = !this.activePaymentStatusSearch
      this.subscriptions.sort((a, b) => {
         return this.activePaymentStatusSearch ? a.paymentStatus.localeCompare(b.paymentStatus) : b.paymentStatus.localeCompare(a.paymentStatus)
      })
    }
    if (sortBy == 'subscription') {
      this.activeSubscriptionSearch = !this.activeSubscriptionSearch
      this.subscriptions.sort((a, b) => {
         return this.activeSubscriptionSearch ? a.subscriptionStart.localeCompare(b.subscriptionStart) : b.subscriptionStart.localeCompare(a.subscriptionStart)
      })
    }
    if (sortBy == 'expiration') {
      this.activeExpirationSearch = !this.activeExpirationSearch
      this.subscriptions.sort((a, b) => {
         return this.activeExpirationSearch ? a.expiring.localeCompare(b.expiring) : b.expiring.localeCompare(a.expiring)
      })
    }
    if (sortBy == 'subscriptionStatus') {
      this.activeSubsctiptionStatusSearch = !this.activeSubsctiptionStatusSearch
      this.subscriptions.sort((a, b) => {
         return this.activeSubsctiptionStatusSearch ? a.subscriptionStatus.localeCompare(b.subscriptionStatus) : b.subscriptionStatus.localeCompare(a.subscriptionStatus)
      })
    }
  }

}
