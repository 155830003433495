import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import faker from 'faker';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  id: any = false;
  userData : any;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Edit User')
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getUser();
      }
    )

  }

  getUser() {
    this.isLoading = true
    this.dataService.__post('/user', {id: this.id})
    .subscribe(
      (success) => {
        this.isLoading = false
        this.userData = success
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onEditUser(formData) {

    if(!formData.valid) {
      return false;
    }

    if(formData.value.name.trim() =='' || formData.value.username.trim() == '' || formData.value.email.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return false
    }

    if(formData.value.email.trim().indexOf("@") == -1 || formData.value.email.trim().indexOf(".") == -1) {
      this.dataService.showAlert('error', 'Error', 'Please enter valid email address')
      return false
    }

    formData.value.id = this.id

    this.isLoading = true
    this.dataService.__post('/user/edit', formData.value,'#editUser')
    .subscribe(
      (success) => {
        this.isLoading = false
        this.router.navigateByUrl('/users')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

}
