import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../environments';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  isLoading : boolean = false;
  searching : boolean = false;
  medias : Array<any> = []
  path : any =environment.API_URL

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Social Medias')
    this.getMedias()
  }

  onSearching() {
    this.searching = !this.searching
    if(!this.searching) {
      this.getMedias()
    }
  }

  getMedias() {
    this.isLoading = true
    this.dataService.__post('/medias', {})
    .subscribe(
      (success) => {
        this.medias = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

  onDelete(id) {
    if(!confirm('Are you really want to delete this Social Media?')) return false;

    this.isLoading = true
    this.dataService.__post('/media/delete', {id : id})
    .subscribe(
      (success) => {
        this.isLoading = false
        this.getMedias()
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  onSearch(event) {
    if(event.target.value.trim() == '') {
      this.getMedias()
      return;
    }
    this.isLoading = true
    this.dataService.__post('/medias', {search : event.target.value.trim()})
    .subscribe(
      (success) => {
        this.medias = success 
        this.isLoading = false
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )
  }

}
