import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-create-url',
  templateUrl: './create-url.component.html',
  styleUrls: ['./create-url.component.css']
})
export class CreateUrlComponent implements OnInit {

  LIVE_URL : string = environment.LIVE_URL;


  isLoading : boolean = false;
  randomURL : any = ''
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmopqrstuvwxyz1234567890";
  
  qrcodeImage : File;
 
  constructor(
    private dataService: DataService,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Add URL')
  }

  onFileSelect(files : Array<File>){
    this.qrcodeImage = files[0];
    // console.log('this.qrcodeImage',this.qrcodeImage)
  }

  onCreateURL(formData: any) {

    if(!formData.valid) {
      return false;
    }

    if(!this.qrcodeImage){
      this.dataService.showAlert('error','Invalid Data','Please choose QR code');
      return false;
    }

    var formValues = new FormData();
    formValues.append('qrcode',this.qrcodeImage);

    Object.keys(formData.value).forEach(key => {
      formValues.append(key,formData.value[key]);
    })

    this.isLoading = true
    this.dataService.__post('/url/create', formValues,'#addUrl')
    .subscribe(
      (success) => {
        this.isLoading = false
        this.router.navigateByUrl('/urls')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
 
  
  onGenerate() {
    this.randomURL = this.makeRandom(20, this.possible);
  }

}
