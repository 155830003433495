import { Component, HostListener, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../environments";

// declare var $ : any;

@Component({
  selector: "app-urls",
  templateUrl: "./urls.component.html",
  styleUrls: ["./urls.component.css"],
})
export class UrlsComponent implements OnInit {
  URLS: Array<any> = [];

  isLoading: boolean = false;
  searching: boolean = false;
  LIVE_URL: string = environment.LIVE_URL;
  ASSETS_URL: string = environment.ASSETS_URL;
  filterBy: any = "All";

  isExpiredSoreted : boolean = false;

  sort: any = {
    created_at: 1,
    visits: 1,
  };

  statistics: any = {
    totalURLs: 0,
    totalUsers: 0,
    totalIncome : 0
  };

  totalPages : any = [];
  curruntPage : number = 0;
  limit : number = 100;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService.setTitle("URL's");
    this.getStatistics();
    this.getUrls();
  }


  getUrls( skip = 0 ) {
    this.isLoading = true;
    this.dataService
      .__post("/urls", {
        sort: this.sort,
        skip : skip
      })
      .subscribe(
        (success) => {
          this.URLS = success;
          this.isLoading = false;
        },
        (error) => {
          this.dataService.showAlert("error", "Error", error);
          this.isLoading = false;
        }
      );
  }

  onDelete(id) {
    if (!confirm("Are you really want to delete this URL?")) return false;

    this.isLoading = true;
    this.dataService.__post("/url/delete", { id: id }).subscribe(
      (success) => {
        this.isLoading = false;
        this.getUrls();
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
        this.isLoading = false;
      }
    );
  }

  onSearching() {
    this.searching = !this.searching;
    if (!this.searching) {
      this.getUrls();
    }
  }

  onFilter(value) {
    this.isLoading = true;
    this.dataService.__post("/urls", { status: value }).subscribe(
      (success) => {
        this.URLS = success;
        this.isLoading = false;
        value.trim() == "" ? (this.filterBy = "All") : (this.filterBy = value);
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
        this.isLoading = false;
      }
    );
  }

  onSearch(event) {
    var value = event.target.value;

    if (value.trim() == "" || this.LIVE_URL.includes(value)) {
      this.getUrls();
      return;
    }
    this.isLoading = true;
    this.dataService
      .__post("/urls", { search: event.target.value.trim() })
      .subscribe(
        (success) => {
          this.URLS = success;
          this.isLoading = false;
        },
        (error) => {
          this.dataService.showAlert("error", "Error", error);
          this.isLoading = false;
        }
      );
  }

  downloadURL() {
    var condition: any = "";

    if (this.filterBy !== "All") {
      condition = {
        status: this.filterBy,
      };
    }

    this.dataService
      .__post("/url/download", { condition: condition })
      .subscribe(
        (file) => {
          var link = document.createElement("a");
          link.setAttribute("download", Date.now() + ".csv");
          link.href = file;
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        (error) => {
          this.dataService.showAlert("error", "Error", error);
        }
      );
  }

  _doSort(type) {

    switch (type) {
      case "created_at":
        this.sort.created_at = this.sort.created_at == 1 ? -1 : 1;
        break;

      case "visits":
        this.sort.visits = this.sort.visits == 1 ? -1 : 1;
        break;

      default:
        break;
    }

    this.sort = this.dataService.changeKeyPosition(this.sort,type);

    this.getUrls();
  }

  sortExpied(){
    this.URLS.reverse();

  }

  getStatistics() {
    this.dataService.__post("/statistics", {}).subscribe(
      (success) => {
        this.statistics = success;
        this.totalPages = Array.from(Array(Math.ceil(this.statistics.totalURLs/this.limit)).keys());
        console.log(this.totalPages);
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  }

  changePage(page){

    this.curruntPage = page;
    this.getUrls(this.curruntPage * this.limit);


  }


}

