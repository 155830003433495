import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router) { }

  isLoading : boolean = false;

  ngOnInit() {
    this.dataService.setTitle('Add User')
  }

  onCreateUser(formData) {

    if(!formData.valid) {
      return false;
    }

    if(formData.value.name.trim() =='' || formData.value.username.trim() == '' || formData.value.email.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return false
    }

    if(formData.value.email.trim().indexOf("@") == -1 || formData.value.email.trim().indexOf(".") == -1) {
      this.dataService.showAlert('error', 'Error', 'Please enter valid email address')
      return false
    }

    this.isLoading = true
    this.dataService.__post('/user/create', formData.value, '#addUser')
    .subscribe(
      (success) => {
        this.isLoading = false
        this.router.navigateByUrl('/users')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
        this.isLoading = false
      }
    )

  }

}
