import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {


  id: any = false
  user: any = false
  isLoading : boolean = false
  path : any = environment.ASSETS_URL

  constructor(
    private dataService : DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.id = params['id'];
        this.getUser();
      }
    )
  }

  getUser() {
    this.isLoading = true
    this.dataService.__post('/user', {id: this.id})
    .subscribe(
      (success) => {
        
        this.isLoading = false
        success.profile = success.profile ? success.profile : environment.APP_PATH + '/assets/images/profile/' + success.name.trim().charAt(0).toUpperCase() + '.png'
        this.user = success
      },
      (error) => {
        this.isLoading = false
        console.log('Error : ', error)
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

}
