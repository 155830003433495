import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../environments';

@Component({
  selector: 'app-social-profile',
  templateUrl: './social-profile.component.html',
  styleUrls: ['./social-profile.component.css']
})
export class SocialProfileComponent implements OnInit {

  socialCard : any = false
  loading : boolean = true;
  hasError : boolean = false;
  id : any = ''
  path: any = environment.API_URL

  constructor(
    private dataService: DataService,
    private activatedRoute : ActivatedRoute
  ) { }


  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.id = params.token;
      this.getSocialCard();
    })

  }

  getSocialCard () {
    this.dataService.__post('/user/social-card', {id : this.id})
    .subscribe(
      (success) => {
        setTimeout(()=>{
          this.loading = false;
          this.socialCard = success
        },2000)
      },
      (error) => {
        this.loading = false;
        this.hasError = true;
      }
    )
  }

}
