import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { ViewBusinessProfileComponent } from './cards-view/view-business-profile/view-business-profile.component';
import { ViewCompanyProfileComponent } from './cards-view/view-company-profile/view-company-profile.component';
import { ViewSocialProfileComponent } from './cards-view/view-social-profile/view-social-profile.component';
import { CardsComponent } from './cards/cards.component';
import { CreateSubscriptionComponent } from './create-subscription/create-subscription.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DefaultComponent } from './layouts/default/default.component';
import { LoginComponent } from './login/login.component';
import { CreateMassiveUrlComponent } from './massive-urls/create-massive-url/create-massive-url.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { CreateMediaComponent } from './social-media/create-media/create-media.component';
import { EditMediaComponent } from './social-media/edit-media/edit-media.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { SocialProfileComponent } from './social-profile/social-profile.component';
import { EditSubscriptionComponent } from './subscriptions/edit-subscription/edit-subscription.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { CreateUrlComponent } from './urls/create-url/create-url.component';
import { EditUrlComponent } from './urls/edit-url/edit-url.component';
import { UrlsComponent } from './urls/urls.component';
import { CreateBusinessComponent } from './users/create-business/create-business.component';
import { CreateSocialComponent } from './users/create-social/create-social.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { CreateVcardComponent } from './users/create-vcard/create-vcard.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { UsersComponent } from './users/users.component';
import { ViewUserComponent } from './users/view-user/view-user.component';
import { VcardProfileComponent } from './vcard-profile/vcard-profile.component';

const routes: Routes = [
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path : 'reset-link',
    component : ResetLinkComponent
  },
  {
    path : 'forgot-password/:id',
    component : ForgotPasswordComponent
  },
  {
    path : 's/:token',
    component : SocialProfileComponent
  },
  {
    path : 'v/:token',
    component : VcardProfileComponent
  },
  {
    path : 'b/:token',
    component : BusinessProfileComponent
  },
  {
    path : '',
    component : DefaultComponent,
    canActivate : [AuthGuard],
    children : [
      {
        path : 'dashboard',
        component : DashboardComponent
      },
      {
        path : '',
        component : DashboardComponent
      },
      {
        path : 'profile',
        component : ProfileComponent
      },
      {
        path : 'urls',
        component : UrlsComponent
      },
      {
        path : 'urls/create',
        component : CreateUrlComponent
      },
      {
        path : 'urls/edit/:id',
        component : EditUrlComponent
      },
      {
        path : 'massive-url/create',
        component : CreateMassiveUrlComponent
      },
      {
        path : 'medias',
        component : SocialMediaComponent
      },
      {
        path : 'medias/create',
        component : CreateMediaComponent
      },
      {
        path : 'medias/edit/:id',
        component : EditMediaComponent
      },
      {
        path : 'users',
        component : UsersComponent
      },
      {
        path : 'users/view/:id',
        component : ViewUserComponent
      },
      {
        path : 'users/create',
        component : CreateUserComponent
      },
      {
        path : 'users/create/social/:user',
        component : CreateSocialComponent
      },
      {
        path : 'users/create/business/:user',
        component : CreateBusinessComponent
      },
      {
        path : 'users/create/v-card/:user',
        component : CreateVcardComponent
      },
      {
        path : 'users/edit/:id',
        component : EditUserComponent
      },
      {
        path : 'cards',
        component : CardsComponent
      },
      {
        path : 'cards/social-profile/:id',
        component : ViewSocialProfileComponent
      },
      {
        path : 'cards/business-profile/:id',
        component : ViewBusinessProfileComponent
      },
      {
        path : 'cards/company-profile/:id',
        component : ViewCompanyProfileComponent
      },
      {
        path : 'subscriptions',
        component : SubscriptionsComponent
      },
      {
        path : 'subscriptions/add',
        component : CreateSubscriptionComponent
      },
      {
        path : 'subscriptions/edit/:id',
        component : EditSubscriptionComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
