import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-edit-media',
  templateUrl: './edit-media.component.html',
  styleUrls: ['./edit-media.component.css']
})
export class EditMediaComponent implements OnInit {

  id : any = false
  mediaData : any = false
  icon : any = false

  constructor(
    private dataService : DataService,
    private route : ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Edit Social Media')
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getMedia()
      }
    )
  }

  onChangeFile(event) {
    this.icon = event.target.files[0]
  }

  getMedia() {
    this.dataService.__post('/media', {id: this.id})
    .subscribe(
      (success) => {
        this.mediaData = success 
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

  onSubmit(formData) {

    const data = new FormData();

    if(formData.value.name.trim() == '' || formData.value.placeholder.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return;
    }

    if(this.icon) {
      data.append('icon', this.icon)
    }

    data.append('id', this.id)
    data.append('name', formData.value.name)
    data.append('placeholder', formData.value.placeholder)
    data.append('mediaUrl', formData.value.url)

    this.dataService.__post('/media/edit', data, '#editMedia')
    .subscribe(
      (success) => {
        this.router.navigateByUrl('/medias')
      },
      (error) => {
        console.log(error)
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

}
