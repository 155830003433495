import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { DataService } from "../data.service";
import { environment } from "../environments";

import Highcharts from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";


import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DaterangepickerDirective } from "ngx-daterangepicker-material";


import moment from 'moment'; 


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  users: Array<any> = [];

  statistics: any = {
    totalURLs: 0,
    totalUsers: 0,
    totalIncome : 0
  };

  countryData : Array<any> = []
  countryDataPer : Array<any> = []
  total : any = 0

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  

  chartColors : any = {
    url : {
      borderColor: '#c2095a',
      backgroundColor: 'rgba(194,9,90)',
    },
    cities : {
      borderColor: '#F58A07',
      backgroundColor: '#F58A07',
    },
    country : {
      borderColor: '#791E94',
      backgroundColor: 'rgba(121,30,148)',
    },
    os : {
      borderColor: '#004643',
      backgroundColor: 'rgba(0,70,67)',
    },
  }


  lineChartFilter : string = 'All';

  chartOptions: any = {
    chart: {
      map: worldMap
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ''
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox"
      }
    },
    legend: {
      enabled: false
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        type: "map",
        name: "Random data",
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        dataLabels: {
          enabled: false,
          format: "{point.name}"
        },
        allAreas: true,
        data: this.countryData
      }
    ]
  };

  lineChartData: ChartDataSets[] = [
    { 
      data: [], label: 'URL Visited' 
    },
  ];
  lineChartLabels: Label[] = [];
  lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: { beginAtZero: true },
      }]
    }
  };


  lineChartColors: Color[] = [
    this.chartColors.url
  ];

  lineChartType = 'bar';

  lineChartLegend = false;
  lineChartPlugins = [];

  updateFlag = false;
  customRange : any = {
    startDate : moment(),
    endDate : moment()
  };


  activeCountrySort : boolean = false;
  activeCountryPr : boolean = false;

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }



  activeDataRender : string = 'URL Visited';
  
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;


  constructor(
    private Auth: AuthService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.getStatistics();
    this.loadCountryChart();
    this.getUsers();
    this.loadChart('All',this.activeDataRender)
    this.dataService.setTitle('Home')
  }

  ngAfterViewInit() {
  }

  getStatistics() {
    this.dataService.__post("/statistics", {}).subscribe(
      (success) => {
        this.statistics = success;
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  }

  getUsers() {
    this.dataService.__post("/users", { limit: 10 }).subscribe(
      (success) => {
        success.forEach((user) => {
          user.profile =
            environment.APP_PATH +
            "/assets/images/profile/" +
            user.name.trim().charAt(0).toUpperCase() +
            ".png";
        });
        this.users = success;
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  }

  onDelete(id) {
    if (!confirm("Are you really want to delete this User?")) return false;
    this.dataService.__post("/user/delete", { id: id }).subscribe(
      (success) => {
        this.getUsers();
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
      }
    );
  }


  loadChart(type = 'All',dataType){

    this.activeDataRender = dataType;
    this.lineChartFilter = type;

    var data : any = {
      type : type,
      dataLoad : dataType
    }

    if(type == 'Custom'){
      data.range = {
        start : this.customRange.startDate.format('YYYY-MM-DD 00:00:00'),
        end : this.customRange.endDate.format('YYYY-MM-DD 23:23:59'),
      }
    }

    this.dataService.__post('/url/chart',data)
    .subscribe(
      (loadedChartData) => {

        var values = [];
        var labels = [];
        this.total = 0;

        loadedChartData.forEach(item => {
          this.total += item.count;

          if(dataType == 'URL Visited'){
            labels.push(item._id.date);
          } else{
            labels.push(item._id);
          }

          values.push(item.count);
        });

        

        switch(dataType){

          case 'Cities':

            this.lineChartColors = [
              this.chartColors.cities
            ];

            // this.lineChartColors = [ 
            //   {
            //     backgroundColor: ["#C8AD55", "#703D57", "#47682C", "#F58A07", "#477998", "#EF8A17", "#330036", "#034C3C", "#7E007B", "#4D5359"],
            //   },

            //  ];
            
            break;

          case 'Contries':

            this.lineChartColors = [
              this.chartColors.country
            ];
            
            break;

          case 'Operating Systems':


            this.lineChartColors = [ 
              {
                borderWidth : 0,
                backgroundColor: ["#C8AD55", "#703D57", "#47682C", "#F58A07", "#477998", "#EF8A17", "#330036", "#034C3C", "#7E007B", "#4D5359"],
              },

             ];
            
            break;


          default:
            this.lineChartColors = [
              this.chartColors.url
            ];
            

        }


        this.lineChartData[0].data = values;
        this.lineChartLabels = labels;


      },
      (error) => {
        console.log('Error',error)
      }
    )
  }


  loadCountryChart(){
    

    this.dataService.__post('/url/country/chart',{})
    .subscribe((countryChart) => {
      this.countryData = countryChart.chartData;
      this.countryDataPer = countryChart.chartPrData;
      this.chartOptions.series[0].data = this.countryData;
      this.updateFlag = true;

    },
    (error) => {
      console.log('Error',error);
    })

  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  datesUpdated(event){
   this.loadChart('Custom',this.activeDataRender)
  }


  onSort(sortBy) {

  
    if(sortBy == 'name') this.countryDataPer.sort((a,b) => {
      this.activeCountrySort = !this.activeCountrySort;
      return this.activeCountrySort ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    })
    
    if(sortBy == 'pr') this.countryDataPer.sort((a,b) =>  {
      this.activeCountryPr = !this.activeCountryPr;
      return this.activeCountryPr ? a.pr - b.pr :  b.pr - a.pr;
    })
  
  }



  loadVisitedData(type){
    
  }


}
